import React from "react"
import styled from "styled-components"
import { Provider } from "../theme"
import { Page, Container, Card, clearfix, TextBlock } from "../components"

const Wrapper = styled.div`
  ${clearfix}
`

export default () => (
  <Provider>
    <Page>
      <Container>
        <h2>404 - Can't find what you're looking for, sorry!</h2>
        <Card>
          <Card.Section>
            <Wrapper>
              <TextBlock>
                <p>The page you're looking for doesn't seem to exist.</p>
                <p>
                  Would you like to visit <a href="/">my home page</a> instead?
                </p>
              </TextBlock>
            </Wrapper>
          </Card.Section>
        </Card>
      </Container>
    </Page>
  </Provider>
)
